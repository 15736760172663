import React from "react"

// @ts-ignore
import image from "./../../content/assets/images/IMG_2608.jpg"

import Header from "./../components/header"
import { Link } from "gatsby"
import Layout from "../components/layout"

export default () => (
  <Layout>
    <h1>Arbeid pågår</h1>
    <p>
      Velkommen til mitt lille hjørnet av det kjempestore internettet! Her er
      det ikke mye å finne for øyeblikket, men takk for oppmerksomheten :-)
    </p>
    <img src={image} alt="inspiration"></img>
  </Layout>
)
